import React from 'react';
import ShowFeaturePage from './ShowFeaturePage';
import ProgrammingLanguagePage from './ProgrammingLanguagePage';
import ClassWiseTutorialPage from './ClassWiseTutorialPage';
import InterviewQuestionPage from './InterviewQuestionPage';
import PersonalWorkplacePage from './PersonalWorkplacePage';
import CompilerEditorPage from './CompilerEditorPage';
import ProvideQuizPage from './ProvideQuizPage';
import OnlineExamPage from './OnlineExamPage';

const LandingPage = () => {
  return (
    <div>
      <ShowFeaturePage />
      <ProgrammingLanguagePage />
      <ClassWiseTutorialPage />
      <InterviewQuestionPage />
      <PersonalWorkplacePage />
      <CompilerEditorPage />
      <ProvideQuizPage />
      <OnlineExamPage />
    </div>
  );
};

export default LandingPage;
