import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, Paper, List, ListItem, ListItemText, Divider, Skeleton } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import "./css/code.css"; // Ensure your custom CSS file is included

const TopicDetailsPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const { subjectId, topicId } = useParams();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [topicData, setTopicData] = useState([]);
    const [subjectName, setSubjectName] = useState('');
    const [topicSubjectName, setTopicSubjectName] = useState('');
    const [headingName, setHeadingName] = useState('');
    const [topicHeadings, setTopicHeadings] = useState([]);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const [paginationInfo, setPaginationInfo] = useState({
        next_page_url: null,
        prev_page_url: null,
    });

    const fetchTopicHeadings = useCallback(async () => {
        setLoading(true); // Start loading
        try {
            const response = await fetch(`${apiUrl}/portal/get_Topic_heading?subject_id=${subjectId}`);
            const data = await response.json();
            if (data.status) {
                setTopicHeadings(data.data);
                if (data.data.length > 0) {
                    setSubjectName(data.data[0].subject_name);
                }
            }
        } catch (error) {
            console.error('Failed to fetch topic headings:', error);
        } finally {
            setLoading(false); // End loading after the fetch is complete
        }
    }, [subjectId, apiUrl]);

    const fetchSubjectWiseTopic = useCallback(async (page) => {
        setLoading(true); // Start loading
        try {
            const queryParams = new URLSearchParams({
                subject_id: subjectId,
                page,
            });

            if (topicId) {
                queryParams.append("topic_id", topicId);
            }

            const apiUrlWithParams = `${apiUrl}/portal/fetch_topic_details?${queryParams.toString()}`;
            const response = await fetch(apiUrlWithParams);
            const data = await response.json();
            if (data.status) {
                setTopicData(data.data.data);
                setPaginationInfo({
                    next_page_url: data.data.next_page_url,
                    prev_page_url: data.data.prev_page_url,
                });
                if (data.data.data.length > 0) {
                    setHeadingName(data.data.data[0].heading);
                    setTopicSubjectName(data.data.data[0].subject_name);
                } else if (!topicId) {
                    setHeadingName('No topics available');
                }
            }
        } catch (error) {
            console.error('Failed to fetch Topics:', error);
        } finally {
            setLoading(false); // End loading after the fetch is complete
        }
    }, [subjectId, topicId, apiUrl]);

    useEffect(() => {
        fetchSubjectWiseTopic(currentPage);
        fetchTopicHeadings();
    }, [fetchSubjectWiseTopic, fetchTopicHeadings, currentPage]);

    const handleNextPage = () => {
        if (paginationInfo.next_page_url) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (paginationInfo.prev_page_url && currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const handleItemClick = (subjectId, topicId) => {
        navigate(`/portal/subject/${subjectId}/${topicId}`);
    };

    return (
        <Grid container>
            {/* Sidebar */}
            <Grid item xs={12} sm={3} md={2.5} sx={{ backgroundColor: '#9F63FF', color: 'white', height: 'auto', borderRadius: 2, marginTop: 2 }}>
                <Typography
                    fontWeight="bold"
                    gutterBottom
                    variant="h5"
                    textAlign="center"
                    sx={{ color: 'white', marginTop: 2 }}
                >
                    {loading ? (
                        <Skeleton
                            width="80%"
                            sx={{ mx: 'auto' }} // Centers the Skeleton horizontally
                        />
                    ) : (
                        `${subjectName} Tutorial`
                    )}
                </Typography>
                <List sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {loading
                        ? Array.from({ length: 8 }).map((_, index) => (
                            <Skeleton key={index} height={50} sx={{ margin: 1 }} />
                        ))
                        : topicHeadings.map((item) => (
                            <ListItem
                                button
                                key={item.id}
                                onMouseEnter={() => setHoveredItem(item.id)}
                                onMouseLeave={() => setHoveredItem(null)}
                                onClick={() => handleItemClick(subjectId, item.id)}
                            >
                                <ListItemText
                                    primary={`${item.subject_name} - ${item.heading}`}
                                    sx={{ color: 'white' }}
                                />
                                {hoveredItem === item.id && (
                                    <ArrowForwardIcon sx={{ color: 'white', marginLeft: 'auto' }} />
                                )}
                            </ListItem>
                        ))}
                </List>
            </Grid>

            {/* Content */}
            <Grid item xs={12} sm={9} md={9.5} sx={{ padding: '10px' }}>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    textAlign="center"
                    gutterBottom
                    sx={{ marginTop: 0.5 }}
                >
                    {loading ? (
                        <Skeleton
                            width="80%"
                            sx={{ mx: 'auto' }} // Centers the Skeleton horizontally
                        />
                    ) : (
                        `${topicSubjectName} - ${headingName}`
                    )}
                </Typography>
                <Divider sx={{ marginBottom: '5px' }} />
                <Grid item xs={12} sx={{ marginTop: '20px' }}>
                    <Box>
                        {/* Skeleton Loader for Dynamic Topic Content */}
                        {loading ? (
                            <>
                                <Skeleton variant="rectangular" height={200} sx={{ marginBottom: 2 }} />
                                <Skeleton width="40%" />
                                <Skeleton width="80%" />
                                <Skeleton width="90%" />
                            </>
                        ) : (
                            // Dynamic Topic Content
                            topicData.map((t) => (
                                <Paper elevation={3} sx={{ padding: '5px' }} key={t.id}>
                                    <Typography paragraph margin={1}>
                                        <ReactQuill
                                            value={t.content}
                                            readOnly={true}
                                            theme="bubble"
                                            className="custom-quill-text"
                                        />
                                    </Typography>
                                </Paper>
                            ))
                        )}

                        {/* Skeleton Loader for Navigation Buttons */}
                        {loading ? (
                            <>
                                <Skeleton variant="rectangular" height={35} width="45%" />
                                <Skeleton variant="rectangular" height={35} width="45%" />
                            </>
                        ) : (
                            // Navigation Buttons at the Bottom
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    startIcon={<ArrowLeftIcon />}
                                    onClick={handlePreviousPage}
                                    disabled={!paginationInfo.prev_page_url}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    endIcon={<ArrowRightIcon />}
                                    onClick={handleNextPage}
                                    disabled={!paginationInfo.next_page_url}
                                >
                                    Next
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TopicDetailsPage;
