import React, { useEffect } from 'react';
import './App.css';
// Import GA4
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

//Portal Route
import PortalRoutes from './components/Portal/Route/PortalRoutes';
// Admin Route
import AdminRoutes from './components/Admin/Route/AdminRoutes';

const App = () => {
  const location = useLocation();

  // Initialize GA4 and track page views
  useEffect(() => {
    // Initialize GA4 with your Measurement ID
    ReactGA.initialize('G-ERV17HSB66'); // Replace with your own GA Measurement ID
    
    // Track initial pageview
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);
  return (
    <Routes>
      {/* Portal Route Path */}
      <Route path="/*" element={<PortalRoutes />} />
      {/* Admin Route Path */}
      <Route path="/admin/*" element={<AdminRoutes />} />
    </Routes>
  );
};

// Main App component wrapped in Router
const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;
