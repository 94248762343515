import React, { useState, useEffect, useCallback } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { styled, alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MenuSection from "./MenuSection";
import { useNavigate } from "react-router-dom";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: "white",
  border: `1px solid ${alpha(theme.palette.common.black, 0.15)}`,
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    transform: "scale(1.02)",
  },
  "&:focus-within": {
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
    transform: "scale(1.02)",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#5b5858",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#5b5858",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
    color: "#5b5858",
  },
}));

const Header = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const apiUrl = process.env.REACT_APP_API_URL;
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarPath, setAvatarPath] = useState(
    localStorage.getItem("avatar") || "/image/portal/avatar.png"
  );

  const handleMenuOpen = (event) => {
    const isAuthenticate = localStorage.getItem("is_authenticate") === "true";
    const authToken = localStorage.getItem("auth_token");

    if (!isAuthenticate || !authToken) {
      navigate("/login");
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => setAnchorEl(null);
  const isMenuOpen = Boolean(anchorEl);

  const fetchAvatar = useCallback(async () => {
    const authToken = localStorage.getItem("auth_token");
    if (!authToken) return;
  
    try {
      const response = await fetch(`${apiUrl}/portal/get_avatar`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
      });
  
      const data = await response.json();
      if (data.status && data.data?.path) {
        if (localStorage.getItem('avatar')) {
          localStorage.removeItem('avatar');
        }
        setAvatarPath(data.data.path);
        localStorage.setItem("avatar", data.data.path);
      }
    } catch (error) {
      console.error("Failed to fetch avatar:", error);
    }
  }, [apiUrl]); // Add apiUrl to dependencies
  
  // Use the memoized fetchAvatar function in useEffect
  useEffect(() => {
    fetchAvatar();
  }, [fetchAvatar]);

  return (
    <AppBar
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
        color: "transparent",
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
        overflow: "hidden",
      }}
      position="sticky"
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Typography
            variant="h5"
            noWrap
            sx={{
              fontWeight: "bold",
              color: "#9F63FF",
            }}
          >
            Knowledge
            <span style={{ color: "#2eee07" }}>Lift</span>
          </Typography>
        </Box>

        {isMobile && (
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar
              alt="Avatar"
              src={avatarPath}
              sx={{
                width: 40,
                height: 40,
                transition: "transform 0.3s ease-in-out",
                "&:hover": { transform: "scale(1.1)" },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </IconButton>
        )}

        {!isMobile && (
          <Box sx={{ flexGrow: 2, display: "flex", justifyContent: "center" }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        )}

        {!isMobile && (
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar
              alt="Avatar"
              src={avatarPath}
              sx={{
                width: 40,
                height: 40,
                transition: "transform 0.3s ease-in-out",
                "&:hover": { transform: "scale(1.1)" },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </IconButton>
        )}
      </Toolbar>

      {isMobile && (
        <Box sx={{ p: 1 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
      )}
      <MenuSection
        anchorEl={anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={handleMenuClose}
        apiUrl={apiUrl}
        setAnchorEl={setAnchorEl}
        fetchAvatar={fetchAvatar}
      />
    </AppBar>
  );
};

export default Header;
