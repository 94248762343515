import React, { useState } from "react";
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Box,
    IconButton,
    Tooltip,
    Alert,
    Snackbar,
} from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios for API calls

const OnlineExamPage = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [openSecondModal, setOpenSecondModal] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [qrCodeData, setQrCodeData] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // State for error messages
    const [successMessage, setSuccessMessage] = useState(""); // State for success messages

    // New States for Second Modal
    const [teacherName, setTeacherName] = useState("");
    const [subject, setSubject] = useState("");
    const [paper, setPaper] = useState("");
    const [topic, setTopic] = useState("");
    const [examType, setExamType] = useState("");
    const [questionPaperName, setQuestionPaperName] = useState("");
    const [examStartDate, setStartExamDate] = useState("");
    const [examEndDate, setExamEndDate] = useState("");

    // Handle opening the modal
    const handleOpen = () => {
        if (localStorage.getItem("is_authenticate") === "true") {
            setOpenModal(true);
        } else {
            if (localStorage.getItem("previous_page")) {
                localStorage.removeItem("previous_page");
            }
            localStorage.setItem("previous_page", `/`);
            navigate("/login");
        }
    };

    // Handle closing the modal
    const handleClose = () => {
        setOpenModal(false);
        setSchoolName(""); // Clear school name when closing
        setQrCodeData(""); // Clear QR code data when closing
        setErrorMessage(""); // Clear error messages
        setSuccessMessage(""); // Clear success messages
    };

    // Handle submit
    const handleSubmit = async () => {
        if (!schoolName) {
            setErrorMessage("Institution name is required.");
            return;
        }

        try {
            const token = localStorage.getItem("auth_token"); // Assuming the token is stored in localStorage
            const response = await axios.post(
                "http://127.0.0.1:8000/api/portal/create_institution",
                { institution_name: schoolName },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data) {
                const uniqueId = response.data.institution.institution_generate_id;
                setQrCodeData(uniqueId);
                setSuccessMessage(response.data.message);
            }
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || "An error occurred. Please try again."
            );
        }
    };

    // Handle copy to clipboard
    const handleCopy = () => {
        navigator.clipboard.writeText(qrCodeData);
        setAlertOpen(true); // Show the alert
    };

    // Close alert
    const handleAlertClose = () => {
        setAlertOpen(false); // Hide the alert
    };

    const handleNext = () => {
        setOpenModal(false);
        setOpenSecondModal(true);
    };

    const handleSecondModalClose = () => {
        setOpenSecondModal(false);
    };


    return (
        <Container maxWidth="xl" style={{ marginTop: "3rem" }}>
            <Typography variant="h4" fontWeight="bold" textAlign="center" gutterBottom>
                Online Exam Platform
            </Typography>
            <Typography
                variant="body1"
                textAlign="center"
                color="textSecondary"
                gutterBottom
            >
                Empowering Teachers and Students to excel in academics through an online
                exam platform.
            </Typography>
            <Grid container spacing={4} justifyContent="center" style={{ marginTop: 1 }}>
                {/* Become a Teacher */}
                <Grid item xs={12} md={6}>
                    <Card elevation={5} style={{ display: "flex", height: "100%" }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="image/portal/teacher.png"
                                    alt="Teacher"
                                    style={{ objectFit: "cover", borderRadius: "8px" }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CardContent>
                                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                                        Become a Teacher
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        Create and manage exams for your students. Track their
                                        progress and provide feedback to help them succeed.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleOpen}
                                    >
                                        Get Started
                                    </Button>
                                </CardActions>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                {/* Become a Student */}
                <Grid item xs={12} md={6}>
                    <Card elevation={5} style={{ display: "flex", height: "100%" }}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <CardContent>
                                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                                        Become a Student
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        Join online exams, improve your knowledge, and track your
                                        progress in real-time to stay motivated and excel in your
                                        studies.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="secondary" fullWidth>
                                        Enroll Now
                                    </Button>
                                </CardActions>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image="image/portal/student.png"
                                    alt="Student"
                                    style={{ objectFit: "cover", borderRadius: "8px" }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            {/* Modal for Entering School/Tuition Name */}
            <Dialog open={openModal} onClose={handleClose}>
                <DialogTitle>Enter Your Institution Name</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center" gap={1} marginBottom={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Institution Name"
                            type="text"
                            size="small"
                            fullWidth
                            value={schoolName}
                            onChange={(e) => setSchoolName(e.target.value)}
                            required
                        />
                        <Button
                            onClick={handleSubmit}
                            color="primary"
                            variant="contained"
                            sx={{ color: "white" }}
                        >
                            Submit
                        </Button>
                    </Box>
                    {errorMessage && (
                        <Typography color="error" variant="body2" gutterBottom>
                            {errorMessage}
                        </Typography>
                    )}
                    {successMessage && (
                        <Typography color="success" variant="body2" gutterBottom>
                            {successMessage}
                        </Typography>
                    )}
                    {qrCodeData && (
                        <div style={{ textAlign: "center", marginTop: "1rem" }}>
                            <Typography gutterBottom>
                                Share Institution QR Code For Student
                            </Typography>
                            <QRCodeSVG value={qrCodeData} />
                            <Typography gutterBottom>
                                Share Institution ID Code For Student
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Typography variant="h5" fontWeight="bold" gutterBottom>
                                    {qrCodeData}
                                </Typography>
                                <Tooltip title="Copy">
                                    <IconButton onClick={handleCopy} color="success">
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="error" sx={{ color: "white" }}>
                        Cancel
                    </Button>
                    {qrCodeData && (
                        <Button variant="contained" color="success" sx={{ color: "white" }} onClick={handleNext}>
                            Next
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Second Modal */}
            <Dialog open={openSecondModal} onClose={handleSecondModalClose}>
                <DialogTitle>Enter Exam Details</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                margin="dense"
                                label="Teacher Name"
                                type="text"
                                fullWidth
                                value={teacherName}
                                onChange={(e) => setTeacherName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Choose Subject"
                                type="text"
                                fullWidth
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Choose Paper"
                                type="text"
                                fullWidth
                                value={paper}
                                onChange={(e) => setPaper(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Enter Topic"
                                type="text"
                                fullWidth
                                value={topic}
                                onChange={(e) => setTopic(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Choose Exam Type"
                                type="text"
                                fullWidth
                                value={examType}
                                onChange={(e) => setExamType(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/* Full-width for Question Paper Name */}
                            <TextField
                                margin="dense"
                                label="Question Paper Name"
                                type="text"
                                fullWidth
                                value={questionPaperName}
                                onChange={(e) => setQuestionPaperName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Exam Start Date"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={examStartDate}
                                onChange={(e) => setStartExamDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                margin="dense"
                                label="Exam End Date"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={examEndDate}
                                onChange={(e) => setExamEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSecondModalClose}
                        variant="contained"
                        color="error"
                        sx={{ color: "white" }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" sx={{ color: "white" }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


            {/* Snackbar Alert */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleAlertClose} severity="success" sx={{ width: "100%" }}>
                    Copied Institution ID: {qrCodeData}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default OnlineExamPage;
